.input-container{
    position: relative;
}

.ant-picker-panel-container {
  border-radius: 10px;
  font-family: "Helvetica";
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #33CC66;
  border-radius: 6px;
  content: '';
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #33CC66;
  border-radius: 6px;
}

.SimpleDatePicker{
    transition: box-shadow 0.1s ease-in-out;
    position: relative;
    padding: 1px;
    //box-shadow: none !important;
    border: 1px solid #a4a4a4;


    &.ant-picker-focused{
        border: 2px solid #67C971;
        padding: 0px;

        .ant-picker-suffix{
            right: 6px;
        }
    }

    .ant-picker-input{
        height: 100%;
        input{
            padding-left: 12px;
            padding-right: 26px;
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
        .ant-picker-suffix{
            position: absolute;
            right: 8px;
            color: black;
        }

        .ant-picker-clear{
            position: absolute;
            right: 8px;
            z-index: 2;
        }
    }
}

.SimpleDatePicker.not-valid{
    padding: 0;
}