.server-notifications-wrapper {
  transition: all 0.5s;
  z-index: 20000;
  position: absolute;
  top: 100px;
  right: -420px;
}

.server-notifications-wrapper.shown{
  right: 16px;
}
