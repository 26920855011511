@tailwind base;

@tailwind components;

div[id="root"] {
    font-family: 'Gilroy', serif;
}


body {

    font-style: normal;
    color: white;
    background: black;
}

header {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 84px;
    margin: 0px auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.main-background {
    background: linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.main-gradient {
    background: radial-gradient(
            circle,
            rgba(50, 187, 129, 0.2) 0%,
            rgba(41, 128, 164, 0.2) 45%,
            rgba(24, 25, 51, 0.2) 100%
    );
    color: white;
    min-height: 100vh;
}

.main-gradient-light {
    background: linear-gradient(0deg, #437D9F 0%, #191932 100%);
    color: white;
    min-height: 100vh;
}

.card-bg {
    background-color: rgba(0, 0, 0, 0.3);
}

@tailwind utilities;
