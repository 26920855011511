.red-button {
  background: #C32604;
  color: #fff;
}

.black-button {
  color: #181833;
  border: 1px solid #181833;
  background: #fff;
}

.green-button {
  background: #33CC66;
  color: #fff;
}

.not-valid-button {
  background: rgba(0, 0, 0, 0.2);
  color: rgba(24, 24, 51, .5);
}