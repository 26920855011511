.input-container{
    position: relative;
    margin-bottom: 28px;
}

.simple-input-label{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    display: block;
    color: black;
}

.simple-input-form.display-as-label{
    display: flex;
    align-items: center;
    gap: 12px;

    & .input-container{
        margin-bottom: 0;
    }

    & .simple-input-label{
        margin-bottom: 0;
        &:after{
            content: ":";
        }
    }
}
