@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-Extrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-ExtraboldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url('fonts/Gilroy/Gilroy-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

html, body {
    font-family: 'Gilroy', sans-serif;
}
